import React from 'react';

const TrustBoxWrapper = ({ children }) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(
        ref.current.querySelector('.trustpilot-widget'),
        true
      );
    }
  }, []);
  return <div ref={ref}>{children}</div>;
};

export function MicroTrustBox() {
  return (
    <TrustBoxWrapper>
      <div style={{ width: '270px', marginTop: '4px' }}>
        <div
          className="trustpilot-widget"
          data-locale="en-US"
          data-template-id="5419b732fbfb950b10de65e5"
          data-businessunit-id="55394aa90000ff00057ef804"
          data-style-height="24px"
          data-style-width="100%"
          data-theme="light"
        >
          <a
            href="https://www.trustpilot.com/review/suretybonds.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Trustpilot
          </a>
        </div>
      </div>
    </TrustBoxWrapper>
  );
}

export function CarouselTrustBox() {
  return (
    <TrustBoxWrapper>
      <div
        className="trustpilot-widget w-full max-w-screen-xl mx-auto"
        data-locale="en-US"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="55394aa90000ff00057ef804"
        data-style-height="140px"
        data-style-width="100%"
        data-theme="light"
        data-tags="Contractor"
        data-stars="4,5"
        data-review-languages="en"
      >
        <a
          href="https://www.trustpilot.com/review/suretybonds.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </TrustBoxWrapper>
  );
}
