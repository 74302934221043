import { StructuredText } from 'react-datocms';
import React from 'react';
import Link from 'next/link';

import { resolveLinkToRecord } from '@utils/helpers';
import { CarouselTrustBox } from './TrustPilot';
import { FeatureList, FeaturePageList } from './FeatureList';
import { ReadyToGetBonded } from './CTA/ReadyToGetBonded';
import { StateMap } from '@components/StateMap/StateMap';
import { FAQ } from '@components/FAQ';
import { CompanyPerks } from './CompanyPerks';

export function StructuredRenderer({ data }) {
  return (
    <StructuredText
      renderLinkToRecord={({ record, children, transformedMeta }) => {
        const url = resolveLinkToRecord(record);
        return (
          <Link href={url}>
            {children}
          </Link>
        );
      }}
      renderBlock={renderBlock}
      data={data}
    ></StructuredText>
  );
}

function renderBlock({ record }) {
  switch (record.__typename) {
    case 'TrustpilotCarouselRecord':
      return <CarouselTrustBox />;
    case 'CompanyPerksBlockRecord':
      return <CompanyPerks />;
    case 'PageListRecord':
      return (
        <FeaturePageList
          heading={record.heading}
          pageItems={record.linkedPages}
          subHeading={record.subHeading}
        />
      );
    case 'StateMapRecord':
      return <StateMap linkToFind={record.link_behavior === 'Bond Finder'} />;
    case 'ProjectOrLicenseCtaRecord':
      return <ReadyToGetBonded />;
    case 'FaqRecord':
      return <FAQ />;
    default:
      return null;
  }
}
