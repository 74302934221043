export function toMoney(amount, { noCents = true } = {}) {
  function getDecimal(amount) {
    const n = Math.abs(amount);
    let dec = n - Math.floor(n);
    dec = (Math.round(dec * 100) / 100).toString();

    if (dec.split('.').length) return dec.split('.')[1];

    return '';
  }
  let integer = amount.toString().split('.')[0];
  let decimal = getDecimal(amount);

  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (noCents) return `$${integer}`;

  if (!decimal || !decimal.length) decimal = '00';
  else if (decimal.length === 1) decimal += '0';
  else if (decimal.length > 2) decimal = decimal.substr(0, 2);

  return `$${integer}.${decimal}`;
}

export function resolveLinkToRecord(record) {
  switch (record._modelApiKey) {
    case 'product':
      return `/${record.state.replace(/\s+/g, '-').toLowerCase()}/${
        record.slug
      }`;
    case 'page':
      return `/${record.slug}`;
    case 'home_page':
      return `/`;
    default:
      return `/${record.slug}`;
  }
}

export function getConnectUrl() {
  return {
    local: 'http://localhost:5000',
    staging: 'https://connect.surety.me',
    production: 'https://connect.suretybonds.com',
  }[process.env.NEXT_PUBLIC_TARGET];
}

export function getGenericConnectUrl(state) {
  return formatConnectUrl({ bondId: getGenericBondId(), amount: 0, state });
}

export function formatConnectUrl({ bondId, amount, state }) {
  let baseUrl = getConnectUrl();
  let formattedUrl = `${baseUrl}/start-application?bondId=${bondId}&contractor=true&bondAmount=${amount}`;
  if (state) formattedUrl += `&bondState=${encodeURIComponent(state)}`;
  return formattedUrl;
}

export function getGenericBondId() {
  return '5bec4646319cfb433ddbfccb';
}

export function getSiteURL() {
  return (
    {
      local: 'https://localhost:3008',
      staging: 'https://demo.contractorbonds.com',
      production: 'https://contractorbonds.com',
    }[process.env.NEXT_PUBLIC_TARGET] || ''
  );
}
