import React from 'react';
import {
  stateShapes,
  labels,
  smallStates,
} from '../../../../data/static-data/stateMapShapes';
import { StateOptions } from 'data/static-data/StateOptions';
import Link from 'next/link';
import { useRouter } from 'next/router';

export function StateMap({ linkToFind, strokeColor, backgroundColor }) {
  let url = linkToFind ? '/find/' : '/';
  return (
    <>
      <div className="hidden sm:block ">
        <StateMapDesktop
          url={url}
          strokeColor={strokeColor}
          backgroundColor={backgroundColor}
        ></StateMapDesktop>
      </div>
      <div className="block sm:hidden mt-40 mb-64">
        <StateDropdown url={url}></StateDropdown>
      </div>
    </>
  );
}
export function StateMapDesktop({
  url,
  strokeColor = 'white',
  backgroundColor = 'white',
}) {
  let [highlightedSmallState, setHighlightedSmallState] = React.useState(null);
  let smallStateList = smallStates.map((state) => state.id);

  return <>
    <svg
      viewBox="0 0 960 600"
      className="fill-blue-surety"
      style={{
        textAnchor: 'middle',
        fill: backgroundColor,
      }}
    >
      {stateShapes.map(({ shape, stateAsPath, id }) => (
        (<Link
          href={url + stateAsPath}
          key={id}
          className="pointer-events-cursor"
          onMouseEnter={
            smallStateList.includes(id)
              ? () => setHighlightedSmallState(id)
              : null
          }
          onMouseLeave={
            smallStateList.includes(id)
              ? () => setHighlightedSmallState(null)
              : null
          }>

          <path
            strokeWidth="1"
            stroke={strokeColor}
            d={shape}
            className={
              highlightedSmallState === id
                ? 'fill-current text-blue-surety-highlight'
                : 'fill-current text-blue-surety hover:text-blue-surety-highlight'
            }
          />
          {/* <g className='pointer-events-none' style={{fill:'white'}}> */}
          <g
            className={`pointer-events-none font-semibold fill-current ${
              id === 'HI' ? 'text-blue-surety' : 'text-white'
            }`}
          >
            {labels[id]}
          </g>

        </Link>)
      ))}
      <g fill="#0d70c2">
        {smallStates.map(({ stateAsPath, id, jsx }) => (
          (<Link href={url + stateAsPath} key={id} className="pointer-events-cursor">

            <g
              className={
                highlightedSmallState === id
                  ? 'fill-current text-blue-surety-highlight'
                  : 'fill-current text-blue-surety hover:text-blue-surety-highlight'
              }
              onMouseEnter={() => setHighlightedSmallState(id)}
              onMouseLeave={() => setHighlightedSmallState(null)}
            >
              {jsx}
            </g>
            <g
              className="pointer-events-none font-semibold"
              style={{ color: 'white', fill: 'white' }}
            >
              {labels[id]}
            </g>

          </Link>)
        ))}
      </g>
    </svg>
  </>;
}

export function StateDropdown({ url }) {
  const router = useRouter();

  return (
    <div className="w-full">
      <select
        value="Select A State"
        className="rounded-lg border w-full border-gray-300 bg-white  px-6 py-4 cursor-pointer hover:border-gray-400  focus:outline-none text-black"
        name=""
        id=""
        onChange={(e) => router.push(url + e.target.value)}
      >
        <option disabled value="Select A State" key="select-a-state">
          Select A State
        </option>
        {StateOptions.map(({ display, stateAsPath }) => {
          return (
            <option value={stateAsPath} key={stateAsPath}>
              {display}
            </option>
          );
        })}
      </select>
    </div>
  );
}
