import React from 'react';

import Link from 'next/link';
import { ArrowRightIcon } from '@heroicons/react/outline';
import { getGenericConnectUrl } from '@utils/helpers';

export function ReadyToGetBonded() {
  return (
    <div
      id="ready-to-get-bonded"
      className="bg-gray-50 border-t font-brand w-full"
    >
      <div className="max-w-3xl mx-auto flex flex-col items-center sm:py-32 py-16 px-4">
        <h2 className="text-4xl text-center font-bold tracking-tight mb-8  text-gray-900 sm:text-5xl">
          <span className="bg-clip-text text-transparent bg-gradient-to-br from-blue-surety-highlight to-blue-surety">
            Ready to get bonded?
          </span>
          <span className="block text-xl sm:text-2xl mt-3">
            What type of bond do you need?
          </span>
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-5 md:gap-8">
          <ProjectOrLicenseCard
            kicker={'PROJECT BOND'}
            content={
              'Project developers often require contractors to be bonded to ensure contracts are fulfilled and projects are completed.'
            }
            href={getGenericConnectUrl()}
          />
          <ProjectOrLicenseCard
            kicker={'LICENSE'}
            content={
              'Contractor license bonds are required at the state, county, or local level to become a licensed contractor and perform work in the area.'
            }
            href={'/find/select-state'}
          />
        </div>
        <div className="text-center text-sm sm:text-base  text-gray-400 mt-8 font-medium">
          Not sure what type bond you need? No Worries! You can browse all of
          our available bonds{' '}
          <Link href="/find/select-state" className="text-gray-700 underline">
            here.
          </Link>
          <br />
          Rather talk to someone? Call{' '}
          <a className="text-gray-700 underline" href="tel:+18003084358">
            1 (800) 308-4358
          </a>{' '}
          now to speak with a contractor bond specialist about your bonding
          needs.
        </div>
      </div>
    </div>
  );
}

function ProjectOrLicenseCard({ kicker, content, href }) {
  return (
    <Link href={href} legacyBehavior>
      <div className="rounded-lg group transform translate-y-0 hover:-translate-y-1 transition-all cursor-pointer shadow-none hover:shadow-md border bg-white py-12 px-8 block">
        <div className="uppercase inline rounded-xl py-2 px-4 font-semibold bg-blue-100 text-blue-surety">
          {kicker}
        </div>
        <div className="mt-8 max-w-sm text-lg text-gray-500">{content}</div>
        <div className="mt-8 flex flex-row items-center text-2xl text-blue-400">
          <div className="bg-white z-10 pr-2">Get Started</div>
          <div className="flex items-center  justify-center">
            <ArrowRightIcon className="w-6 transform translate-y-px" />
          </div>
        </div>
      </div>
    </Link>
  );
}
