import React from 'react';

import Link from 'next/link';
import { StructuredRenderer } from './StructuredRenderer';
import { resolveLinkToRecord } from '@utils/helpers';
export function FeatureList({ heading, subHeading, listItems, icon }) {
  return (
    <div className="w-full flex flex-col items-center mx-auto font-brand">
      {icon && (
        <div className="h-10 w-10 mb-5 bg-gradient-to-br rounded-lg shadow-double-blue from-blue-500 to-blue-700 text-white flex items-center justify-center">
          {icon}
        </div>
      )}

      <h2 className="sm:text-4xl text-2xl text-center leading-snug font-bold tracking-wide mb-3">
        {heading}
      </h2>
      <p className="text-center mb-20 sm:text-lg text-base text-gray-600">
        {subHeading}
      </p>
      <div className="w-full max-w-screen-lg gap-5 mx-auto grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
        {listItems.map((item) => {
          return (
            <FeatureListItem
              key={item.heading}
              heading={item.heading}
              body={item.body}
            ></FeatureListItem>
          );
        })}
      </div>
    </div>
  );
}

export function FeaturePageList({ heading, subHeading, pageItems, icon }) {
  return (
    <div className="w-full flex flex-col items-center mx-auto font-brand">
      {icon && (
        <div className="h-10 w-10 mb-5 bg-gradient-to-br rounded-lg shadow-double-blue from-blue-500 to-blue-700 text-white flex items-center justify-center">
          {icon}
        </div>
      )}

      <h2 className="sm:text-4xl text-2xl text-center leading-snug font-bold tracking-wide mb-3">
        {heading}
      </h2>
      <p className="text-center mb-20 sm:text-lg text-base text-gray-600">
        {subHeading}
      </p>
      <div className="grid font-brand md:grid-cols-2 grid-cols-1  lg:grid-cols-3 gap-10 w-full">
        {pageItems.map((page) => {
          const url = resolveLinkToRecord(page);
          return (
            <FeaturePageItem
              key={page.id}
              url={url}
              kicker={page.kicker}
              blurb={page}
              {...page}
            ></FeaturePageItem>
          );
        })}
      </div>
    </div>
  );
}

export function FeaturePageItem({ kicker, heading, blurb, url }) {
  return (
    <div className="flex flex-col items-center sm:text-left text-center sm:items-start cursor-pointer transform transition-all translate-y-0 hover:-translate-y-1">
      <div className="text leading-snug font-semibold tracking-wide uppercase text-blue-surety">
        {kicker}
      </div>
      <div>
        <h3 className="sm:text-2xl text-lg leading-relaxed mb-3 text-gray-900 font-semibold">
          {heading}
        </h3>
        <p className="sm:text-lg text-base text-gray-500">{blurb}</p>
        <Link
          href={url}
          className="mt-3 flex flex-row sm:justify-start justify-center items-center text-blue-400 underline text-base sm:text-lg font-medium">
          
            See More
          
        </Link>
      </div>
    </div>
  );
}
export function FeatureListItem({ heading, body }) {
  return (
    <div className="flex h-full flex-col  justify-items-center text-left px-5 py-5  border rounded-md">
      <div className="text-xl font-semibold mb-2">{heading}</div>
      <div>{body}</div>
    </div>
  );
}
