import Link from 'next/link';
import React from 'react';

const questions = [
  {
    question: 'How  do I get bonded as a contractor?',
    answer: (
      <p>
        Most{' '}
        <FAQLink href="/license-and-permit-bonds">
          contractor license bonds
        </FAQLink>{' '}
        can be purchased online in minutes! Surety bond applications usually
        require additional information, such as license type, business contact,
        owner information, etc, so be sure to have all necessary information
        available to expedite service.
      </p>
    ),
  },
  {
    question: 'How do I know which bond I need?',
    answer: (
      <p>
        In the vast majority of cases, contractors will either need a{' '}
        <FAQLink href="/license-and-permit-bonds">bond for licensing</FAQLink>{' '}
        or a{' '}
        <FAQLink href="/contract-bonds">
          bond for a specific construction project.
        </FAQLink>{' '}
        Before purchasing a surety bond, contact the obligee (entity requiring
        the bond) to determine the bond type and bond amount you need to obtain.
        Our <FAQLink href="/find">interactive bond finder tool</FAQLink> can
        also help identify which bond you need.
      </p>
    ),
  },
];

export function FAQ(props) {
  return (
    <div className="max-w-screen-lg space-x-0 md:space-x-10 w-full mx-auto py-16 sm:py-32 flex flex-col md:flex-row">
      <div className="lg:max-w-sm md:max-w-xs max-w-full w-full">
        <div className="md:text-2xl lg:text-3xl text-2xl  md:max-w-xs max-w-full font-semibold">
          Frequently Asked Questions
        </div>
        <div className="sm:text-lg text-sm mt-1 leading-relaxed font-medium text-gray-500">
          Have a different question? <br />
          Call{' '}
          <a className="text-blue-500" href="tel:+18003084358">
            1 (800) 308-4358
          </a>{' '}
          to talk with a Contractor Bond Expert today.
        </div>
      </div>
      <div className="flex mt-8 md:mt-0 flex-1 w-full">
        <ul className="flex flex-col space-y-8  w-full">
          {questions.map((q) => {
            return (
              <FAQItem
                key={q.question}
                question={q.question}
                answer={q.answer}
              ></FAQItem>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

function FAQItem({ question, answer }) {
  return (
    <li className="w-full px-0 md:px-3 ">
      <div className="sm:text-xl text-lg font-semibold text-gray-800 ">
        {question}
      </div>
      <div className="sm:text-lg text-base mt-3 text-gray-500">{answer}</div>
    </li>
  );
}

function FAQLink(props) {
  return <>
    <Link
      href={props.href}
      passHref
      className="underline text-blue-500 hover:text-blue-surety">

      {props.children}

    </Link>
  </>;
}
