import React from 'react';
import {
  FeatureList,
  FeatureListItem,
  FeaturePageItem,
  FeaturePageList,
} from './FeatureList';
import Link from 'next/link';
import { LibraryIcon } from '@heroicons/react/outline';

const COMPANY_PERKS = [
  {
    heading: 'Secure',
    body: (
      <>
        Backed by SSL, our online bonding process is secure. We are committed to
        your privacy.
      </>
    ),
  },
  {
    heading: 'Fast',
    body: (
      <>
        We’ve streamlined the bonding process to make your experience as fast
        and easy as possible.
      </>
    ),
  },
  {
    heading: 'Best Prices',
    body: <>We shop the top surety markets to find you the best rate.</>,
  },
  {
    heading: 'Industry Leading Experience',
    body: (
      <>
        Powered by surety experts at SuretyBonds.com, we have over 50,000
        satisfied customers.
      </>
    ),
  },
  {
    heading: 'Digital Bond Delivery',
    body: (
      <>
        Your official bond emailed to you <em>minutes</em> after purchase
      </>
    ),
  },
  {
    heading: 'World Class Customer Service',
    body: (
      <>
        <div>
          Not sure what bond you need? No Worries! Our team of surety experts
          are ready to help
        </div>
        <div className="flex flex-row space-x-2">
          <a className="text-blue-400 underline" href="tel:+18003084358">
            Call
          </a>
          <a
            href="mailto:customercare@suretybonds.com"
            className="text-blue-400 underline"
          >
            Email
          </a>
        </div>
      </>
    ),
  },
];

export function CompanyPerks() {
  return (
    <FeatureList
      icon={
        <div className="h-10 w-10 mb-5 bg-gradient-to-br rounded-lg shadow-double-blue from-blue-500 to-blue-700 text-white flex items-center justify-center">
          <img src="/cb-solo-white.png" alt="" />
        </div>
      }
      heading={'Why ContractorBonds.com?'}
      listItems={COMPANY_PERKS}
      subHeading={'See why our customers love working with us'}
    />
  );
}
